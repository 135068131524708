import Utils from "../../../utils";
import Swal from 'sweetalert2';
import flatpickr from "flatpickr";
import {German} from "flatpickr/dist/l10n/de.js"
import AbsenceEntity from "../../absence/entity";
import TripEntity from "../../trip/entity";
import * as bootstrap from 'bootstrap'
import DefaultController from "../../defaultController";
import {en} from "@fullcalendar/core/internal-common";


export default class TeamsTimetrackHeader extends DefaultController {
    private datepicker: any;
    private absenceModal: any;
    private tripModal: any;
    private absenceTable: any
    private tableElem: any;

    private userId: any;

    async init() {
        this.entity = "absences"
        this.tableElem = jQuery(".teams_header_absences_pending")
        this.tableElem.delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('absence.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('absence.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                await this.request("")
                console.log("this.absenceTable", this.absenceTable)
            }
        });
        jQuery("#navbar-collapse").delegate("button.btn[data-mode]", "click", async (e) => {
            e.preventDefault();
            const btn = e.currentTarget;
            const mode = btn.getAttribute("data-mode");
            await Utils.entity.request(`/api/v1/time_entries/scan`, 'POST', {mode})
            const p = document.querySelector(".time_entry_mode_buttons") as HTMLElement;
            let html = '';
            if (mode === "IN" || mode === "P_OUT") {
                html += `<button type="button" class="btn btn-warning waves-effect waves-light me-2" data-mode="P_IN"> <i class="ti ti-player-pause-filled ti-xs me-1"></i>${window.siteConfig.device === "mobile" ? "": Utils.translate("timetrack.states.pause")}</button>`
                html += `<button type="button" class="btn btn-danger waves-effect waves-light" data-mode="OUT"> <i class="ti ti-player-stop-filled ti-xs me-1"></i>${window.siteConfig.device === "mobile" ? "": Utils.translate("timetrack.states.end")}</button>`
            } else if (mode === "P_IN") {
                html += `<button type="button" class="btn btn-success waves-effect waves-light" data-mode="P_OUT"> <i class="ti ti-player-play-filled ti-xs me-1"></i>${window.siteConfig.device === "mobile" ? "": Utils.translate("timetrack.states.pause_end")}</button>`
            } else if (mode === "OUT") {
                html += `<button type="button" class="btn btn-success waves-effect waves-light" data-mode="IN"> <i class="ti ti-player-play-filled ti-xs me-1"></i>${window.siteConfig.device === "mobile" ? "": Utils.translate("timetrack.states.start")}</button>`
            }
            p.innerHTML = html;
        });
        await Utils.createSelects({}, (elem: any) => {
            return {}
        }, (data: any, entity: any, element: any) => {
            if (element.id === "absence_replacement_user_id") {
                // @ts-ignore
                if(window.activeUser) {
                    // @ts-ignore
                    data = data.filter((u) => {return u.uuid !== window.activeUser.uuid})
                }
            }
            return data
        }, (data: any, entity: any) => {return {}},
            "#navbarAbsenceNewForm .select2");
        let config = {
            mode: 'range',
            onChange: (selectedDates: any, dateStr: any, instance: any) => {
                if (selectedDates && selectedDates[0]) {
                    const fromElem = document.querySelector("#absence_from") as HTMLInputElement;
                    const toElem = document.querySelector("#absence_to") as HTMLInputElement;
                    const userTimezoneOffset = selectedDates[0].getTimezoneOffset() * 60000;
                    if (selectedDates[0]) {
                        fromElem.value = `${selectedDates[0].toISOString()}`
                    }
                    if (selectedDates[1]) {
                        const d = selectedDates[1]
                        d.setHours(23, 59, 59, 999)
                        toElem.value = `${d.toISOString()}`
                    }
                }
            },
            allowInput: true,
            onOpen: (selectedDates: any, dateStr: any, instance: { altInput: any; }) => {
                jQuery(instance.altInput).prop('readonly', true);
            },
            onClose: (selectedDates: any, dateStr: any, instance: { altInput: any; })=>  {
                jQuery(instance.altInput).prop('readonly', false);
                jQuery(instance.altInput).blur();
            },
            showMonths: 2,
            disable: window.activeUser.absenceDays
        }
        if ((window as any).currentLocale === 'de') {
            // @ts-ignore
            config["locale"] = German;
        }
        // @ts-ignore
        this.datepicker = flatpickr((document.querySelector("#absence_daterange") as HTMLElement), config);

        jQuery("#absence_user_id").on("select2:select", async (e: any) => {
            console.log("e", e)
           const val = e.params.data.id;
           this.userId = val;
           await this.request("");
        });

        (document.querySelector("#navbarAbsenceNewForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const form = e.target as HTMLFormElement;
            if (form.checkValidity() && (document.querySelector("#absence_from") as HTMLInputElement).value.length > 1 && (document.querySelector("#absence_to") as HTMLInputElement).value.length > 1) {
                const data = AbsenceEntity.getEntityData(form)
                const reply = await Utils.entity.upsert(data, 'absences')
                if (this.absenceTable) {
                    this.absenceTable.ajax.reload();
                }
                form.reset();
                (document.querySelector("#absence_from") as HTMLInputElement).value = "";
                (document.querySelector("#absence_to") as HTMLInputElement).value = "";
                if (this.datepicker) {
                    this.datepicker.clear();
                }
                console.log("DISABLE", reply.range, window.activeUser.absenceDays.concat(reply.data[0].range), this.datepicker)
                //@ts-ignore
                this.datepicker.set("disable", window.activeUser.absenceDays.concat(reply.data[0].range));
                await this.request("")
                //@ts-ignore
                const $opt = jQuery("<option selected='selected'></option>").val(activeUser.uuid).text(activeUser.name)
                jQuery("#navbarAbsenceNewForm #absence_user_id").append($opt).trigger('change');

                this.toastr.success(`${Utils.translate('absence.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)

            }
        });
        (document.querySelector("#navbarTripNew") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const form = e.target as HTMLFormElement;
            if (form.checkValidity()) {
                const data = TripEntity.getEntityData(form)
                await Utils.entity.upsert(data, 'trips')
                form.reset();
                this.tripModal.hide()
                this.toastr.success(`${Utils.translate('trip.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)

            }
        })

        this.setupTimer();
        this.absenceModal = new bootstrap.Modal((document.querySelector("#navbarAbsenceNew") as HTMLElement));
        this.tripModal = new bootstrap.Modal((document.querySelector("#navbarTripNew") as HTMLElement));
        this.setupEventListeners();

        await this.setupDatatables();
        await this.request("");
        this.bindListeners()
        setInterval(async () => {
            await this.setupTimer();
        }, 1000)
    }

    protected bindListeners() {
    }

    setupDatatables() {
        let columns = [
            {data: 'from'},
            {data: 'to'},
            {data: 'name'},
            {data: 'uuid'}
        ];
        let columnDefs = [
            {
                targets: 0,
                render: (data: any, type: any, full: any, meta: any) => {
                    return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                },
            },
            {
                targets: 1,
                render: (data: any, type: any, full: any, meta: any) => {
                    return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                },
            },
            {
                targets: 2,
                render: (data: any, type: any, full: any, meta: any) => {
                    if (!full.absence_mode && !full.absence_mode.name){
                        console.warn("Absence mode not found!", full)
                        return "";
                    } else {
                        return full.absence_mode.name
                    }
                },
            },
            {
                targets: 3,
                searchable: false,
                orderable: false,
                render: (data: any, type: any, full: any, meta: any) => {
                    if (full.status === "NEW" || (window.activeUser && window.activeUser.role === "admin")) {
                        return `<div class="d-flex align-items-center justify-content-center">
                                  <a href="javascript:void(1)" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    } else if (full.status){
                        return Utils.translate(`absence.status.${full.status.toLowerCase()}`);
                    } else {
                        return ""
                    }
                },
            }
        ]
        if (window.activeUser && window.activeUser.role === "admin") {
            columns = [
                {data: 'user.name'},
                {data: 'from'},
                {data: 'to'},
                {data: 'name'},
                {data: 'uuid'}
            ];
            columnDefs = [
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (!full.absence_mode && !full.absence_mode.name){
                            console.warn("Absence mode not found!", full)
                            return "";
                        } else {
                            return full.absence_mode.name
                        }
                    },
                },
                {
                    targets: 4,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.status === "NEW" || (window.activeUser && window.activeUser.role === "admin")) {
                            return `<div class="d-flex align-items-center justify-content-center">
                                  <a href="javascript:void(1)" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                        } else if (full.status){
                            return Utils.translate(`absence.status.${full.status.toLowerCase()}`);
                        } else {
                            return ""
                        }
                    },
                }
            ]
        }
        this.absenceTable = new DataTable(this.tableElem, {
            ajax: `/api/v1/absences`,
            columns: columns,
            order: [[0, 'desc']],
            columnDefs: columnDefs,
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',

            language: {
                // @ts-ignore
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `${Utils.translate('generic.download')}`,
                    className: 'dt-button btn btn-primary m-2',
                    action: async () => {
                        let csv = '';
                        const csvHeader: any = []
                        this.absenceTable.columns().header().each((column: any, i: number) => {
                            const visible = this.absenceTable.column(i).visible();
                            if (visible) {
                                let content = column.innerHTML
                                if (content.indexOf("<span") !== -1) {
                                    content = content.match(/>(.*)<\/span><span/)[1]
                                }
                                if (content !== "Actions") {

                                }
                                csvHeader.push(content)
                            }
                        })
                        csv += `${csvHeader.join(";")}\n`
                        this.absenceTable.rows().data().each((data: any) => {
                            console.log(data)
                            let content = [new Date(Date.parse(data.from)).toLocaleDateString(),
                                new Date(Date.parse(data.to)).toLocaleDateString(),
                                data.absence_mode.name
                            ]
                            csv += content.join(";") + "\n";
                        })

                        await Utils.download(new Blob(["\ufeff", csv]), 'export.csv')
                    }
                }
            ]
        });
    }

    async setupEventListeners() {
        // @ts-ignore
        const parent = this.absenceModal._element
        const prev = parent.querySelector("#teams_header_absence_prev") as HTMLElement;
        const next = parent.querySelector("#teams_header_absence_next") as HTMLElement;
        prev.addEventListener("click", async (e: any) => {
            e.preventDefault();
            await this.request("minus")
        });

        next.addEventListener("click", async (e: any) => {
            e.preventDefault();
            await this.request("plus")
        })

    }

    async request(mode: string) {
        // @ts-ignore
        const parent = this.absenceModal._element
        const table = parent.querySelector("#teams_header_absence_table") as HTMLElement
        const userLine = parent.querySelector("#teams_header_absence_user") as HTMLElement;
        const yearDisplay = (parent.querySelector("a[data-date]") as HTMLButtonElement);
        // @ts-ignore
        const currentDate = parseInt(yearDisplay.getAttribute("data-date"));
        let newDate = currentDate
        if (mode === "minus") {
            newDate = currentDate - 1
        }
        if (mode === "plus") {
            newDate = currentDate + 1
        }
        const r = await Utils.entity.request(`/api/v1/absences/info?year=${newDate}&user=${this.userId || ""}`, 'GET')
        if (r.status === 200) {
            if (r.data.acting_as) {
                userLine.innerHTML = `${Utils.translate('absence.acting_as')}: ${r.data.acting_as}`
            } else {
                userLine.innerHTML = "";
            }

            yearDisplay.innerHTML = `${newDate}`;
            yearDisplay.setAttribute("data-date", `${newDate}`);
            table.innerHTML = `<tr>
                  <td><b>${Utils.translate('absence.vacation_days')}</b></td>
                  <td>${r.data.allowance}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.pending_days')}</b></td>
                  <td>${r.data.pending}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.approved_days')}</b></td>
                  <td>${r.data.taken}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.left_days')}</b></td>
                  <td>${r.data.left}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.over_hours')}</b></td>
                  <td>${r.data.over_hours}</td>
                </tr>`
            if (this.absenceTable) {
                this.absenceTable.clear();
                this.absenceTable.rows.add(r.data.absences);
                this.absenceTable.draw();
            }
        }
    }

    async setupTimer() {
        const timerWork = document.querySelector(".time_entry_header_work_time") as HTMLElement;
        const timerPause = document.querySelector(".time_entry_header_pause_time") as HTMLElement;
        const r = await Utils.entity.request(`/api/v1/time_entries/today`, 'GET')
        if (r.status === 200) {
            timerWork.innerHTML = `<b>${Utils.translate("timetrack.today_worktime")}:</b> ${new Date(r.working * 1000).toISOString().slice(11, 19)}`
            timerPause.innerHTML = `<b>${Utils.translate("timetrack.today_pausetime")}:</b> ${new Date(r.pause * 1000).toISOString().slice(11, 19)}`
        }
    }
}